<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-8">
                        {{$t('Información del Pago')}}
                    </div>
                    <div class="p-field p-col-4">
                        <Card style="margin-bottom: 2em;">
                            <template #content>
                                IBAN: BDCCCWCU<br>
                                Banco Di Caribe<br>
                                Account holder: International Insurance N.V.<br>
                                Account number: 30016701<br>
                                Address: WTC, Piscadera Bay 2do piso of tm 229. Curacao
                            </template>
                        </Card>
                    </div>
                </div>
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12" v-if="creditos.length>0">
                        <h4>{{$t('Crédito')}}</h4>
                        <small v-show="validationErrors.pago && submitted" class="p-error">{{$t('seleccine')}}</small>
						<DataTable :value="creditos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm" v-if="creditos.length>0">
                            <Column :header="$t('Fecha')" field="fecha" :sortable="true" headerStyle="width: 100px">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
                            <Column :header="$t('Póliza') + '#'" field="nombre" :sortable="true" headerStyle="width: 300px">	
								<template #body="{data}">
									{{data.codigo}}
								</template>
							</Column>
                            <Column :header="$t('Monto')" field="monto" :sortable="true">	
								<template #body="{data}">
                                    <div v-if="data.monto<0" style="color:red;">
                                        {{money('XCG',data.monto)}}
                                    </div>
                                    <div v-else>
                                        {{money('XCG',data.monto)}}
                                    </div>
								</template>
							</Column>
                            <Column :header="$t('Agregar a Factura')">	
								<template #body="{data}">
									<Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(0,data)"/>
                                </template>
                            </Column>
						</DataTable>
					</div>
                    <div class="p-field p-col-12 p-md-12">
                        <h4>{{$t('Pagos Pendientes')}}</h4>
                        <small v-show="validationErrors.pago && submitted" class="p-error">{{$t('seleccine')}}</small>
						<DataTable :value="deudas" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column :header="$t('Fecha')" field="fecha" :sortable="true" headerStyle="width: 100px">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
                            <Column :header="$t('Póliza') + '#'" field="nombre" :sortable="true" headerStyle="width: 300px">	
								<template #body="{data}">
									{{data.codigo}}
								</template>
							</Column>
                            <Column :header="$t('Monto')" field="monto" :sortable="true">	
								<template #body="{data}">
                                    <div v-if="data.monto<0" style="color:red;">
                                        {{money(data.simbolo,data.monto)}}
                                    </div>
                                    <div v-else>
                                        {{money(data.simbolo,data.monto)}}
                                    </div>
								</template>
							</Column>
                            <Column :header="$t('Vencimiento')" field="vencimiento" :sortable="true">	
								<template #body="{data}">
									{{data.vencimiento}}
								</template>
							</Column>
                            <Column :header="$t('Agregar a Factura')">	
								<template #body="{data}">
									<Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(1,data)"/>
                                </template>
                            </Column>
						</DataTable>
					</div>
					<div class="p-col-6"></div>
					<div class="p-col-3"><h4><strong>{{$t('Monto a Pagar')}}:</strong></h4></div>
					<div class="p-col-3"><h4><strong>
                        {{money('XCG',factura_monto)}}
                    </strong></h4></div>
                    
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <i></i>
                    <Button :disabled ="$store.state.loading" :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"  v-if="deudas.length>0"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Pagos','ReportarPago');
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    data () {
        return {
			size: '40vw',
			i18n: null,
            submitted: false,
            validationErrors: {},
            deudas: [],
            creditos: [],
            factura: [],
            apagar: [],
            factura_monto: 0,
            poliza: 0,
        }
    },
	created() {
        this.factura_monto = 0;
		this.i18n = useI18nPlugin();
		Consulta.Ini().then(result => {
            this.deudas = result.consult.deudas;
            this.creditos = result.consult.creditos;
			for (var i=0; i<this.deudas.length; i++){
				this.deudas[i].select = false;
			}
			for (var j=0; j<this.creditos.length; j++){
				this.creditos[j].select = false;
			}
		}); 
	},
    methods: {
        AgregarFactura(caso,data){
            this.apagar = [];
            this.factura_monto = 0;
            this.poliza = 0;
			if(!data.select){
				this.apagar.push(data);
				this.factura_monto = this.factura_monto + parseFloat(data.monto);
			}
			if(caso){
                this.poliza = data.poliza;
				for (var i=0; i<this.deudas.length; i++){
					if(this.deudas[i].id!=data.id){
						this.deudas[i].select = false;
					}               
				}
			} else {
				for (var l=0; l<this.deudas.length; l++){
					if(this.deudas[l].select){
						this.apagar.push(this.deudas[l]);
						this.factura_monto = this.factura_monto + parseFloat(this.deudas[l].monto);
					}
				}
			} 	
			for (var k=0; k<this.creditos.length; k++){
				if(this.creditos[k].select && this.creditos[k].id!=data.id){
					this.apagar.push(this.creditos[k]);
					this.factura_monto = this.factura_monto + parseFloat(this.creditos[k].monto);
				}
			}
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {poliza:this.poliza,factura:this.apagar,factura_monto: this.factura_monto}, pageIndex: 0});
            }
        },
        validateForm() {
            if (this.factura_monto==0)
                this.validationErrors['pago'] = true;
            else
                delete this.validationErrors['pago'];
            if (this.poliza==0)
                this.validationErrors['pago'] = true;
            else
                delete this.validationErrors['pago'];

            return !Object.keys(this.validationErrors).length;
        },
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
        money(currency,number){
            return new Intl.NumberFormat("en", {style: "currency", currency: currency}).format(parseFloat(number).toFixed(2));
		},

    }
}
</script>